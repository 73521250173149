import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4091d027"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "digital-form" }
const _hoisted_2 = {
  key: 0,
  class: "errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApplePay = _resolveComponent("ApplePay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.validationErrors.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validationErrors, (error) => {
            return (_openBlock(), _createElementBlock("div", {
              key: error,
              class: "error"
            }, _toDisplayString(error), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ApplePay, { validationErrors: [] })
  ]))
}