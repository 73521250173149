
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { viewModel } from '@/lib/AppService'
import { getLogger } from '@/lib/log'
import {PaymentInit, PaymentItem, PaymentCurrencyAmount, AppleMerchantValidationEvent, PaymentInstrumentType, PaymentOption, ProcessApplePaymentRequest} from '@/lib/types'

const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const log = logger.write
const debugLog = logger.debug

@Component
export default class ApplePay extends Vue {
    @Prop validationErrors: string[] = []
    vm = viewModel

    async mounted() {
        if (!window.PaymentRequest) {
        debugLog('** PaymentRequest Not Supported by Browser...')
        return;
        }

        const existingScript = document.querySelector('#applePayScript')
        if(existingScript == null || existingScript == undefined)
        {
            const script = document.createElement('script')
            script.id = "applePayScript"
            script.src = `https://applepay.cdn-apple.com/jsapi/1.latest/apple-pay-sdk.js`            
            const div = document.querySelector('#app')
            div?.appendChild(script)
        }

         // Define PaymentMethodData
         const paymentMethodData = [{
            "supportedMethods": "https://apple.com/apple-pay",
            "data": {
                "version": 3,
                "merchantIdentifier": "merchant.com.assurant.enterprisepaymentgateway.sandbox",
                "merchantCapabilities": ["supports3DS"],
                "supportedNetworks": [
                    "amex",
                    "discover",
                    "masterCard",
                    "visa"
                ],
                "countryCode": "US"
            }
        }];
        
        
        const paymentAmount:PaymentCurrencyAmount = {
            currency: this.vm.transactionIntent!.transactionIntent.transactionAmount.currencyCode,
            value: this.vm.transactionIntent!.transactionIntent.transactionAmount.value.toString()}

        debugLog("Intent Amount");
        debugLog(this.vm.transactionIntent!.transactionIntent.transactionAmount.value.toString());

        const paymentTotal:PaymentItem = {
            label: this.vm.transactionIntent!.transactionIntent.transactionDescription,
            amount: paymentAmount}

        const paymentInit: PaymentInit={
            id : this.vm.transactionIntent!.transactionIntent.id,
            total: paymentTotal}

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentInit);
        debugLog("Payment Request Created");

        this.vm.isApplePaySupported = await request.canMakePayment();
        debugLog(this.vm.isApplePaySupported);
    }

    async initiatePayment()
    {
         try {

        this.vm.selectedPaymentOption = this.vm.paymentOptions?.find(p => p.paymentInstrumentType == PaymentInstrumentType.EWallet && p.vendor == "ApplePay");
        
        // Define PaymentMethodData
        const paymentMethodData = [{
            "supportedMethods": "https://apple.com/apple-pay",
            "data": {
                "version": 3,
                "merchantIdentifier": this.vm.appleMerchantIdentifier,
                "merchantCapabilities": ["supports3DS"],
                "supportedNetworks": [
                    "amex",
                    "discover",
                    "masterCard",
                    "visa"
                ],
                "countryCode": "US"
            }
        }];
        

        const paymentAmount:PaymentCurrencyAmount = {
            currency: this.vm.transactionIntent!.transactionIntent.transactionAmount.currencyCode,
            value: this.vm.transactionIntent!.transactionIntent.transactionAmount.value.toString()
            }

        const paymentTotal:PaymentItem = {
            label: this.vm.transactionIntent!.transactionIntent.transactionDescription,
            amount: paymentAmount
        }

        const paymentInit: PaymentInit={
            id : this.vm.transactionIntent!.transactionIntent.id,
            total: paymentTotal
        }

        // Create PaymentRequest
        const request = new PaymentRequest(paymentMethodData, paymentInit);

        request.addEventListener("merchantvalidation", (event)=>{
            this.handleMerchantValidation(event);
        });

        let geolatitude = '';
        let geolongitude = '';

        navigator.geolocation.getCurrentPosition((position: any) => {
            geolatitude = position.coords.latitude.toFixed(2);
            geolongitude = position.coords.longitude.toFixed(2);
        },(error: any) => {
            debugLog(error);
            geolatitude = "1";
            geolongitude = "1";
        })
        
        const response = await request.show();

        if(response.details.token != null && response.details.token.paymentData != null)
        {
           
            debugLog("Payment Sheet Completed");
            debugLog(response);

            await this.vm.processApplePayPayment({
            paymentBundle : response.details.token.paymentData.data,
            publicKey: response.details.token.paymentData.header.ephemeralPublicKey,
            publicKeyHash: response.details.token.paymentData.header.publicKeyHash,
            transactionId: response.details.token.paymentData.header.transactionId,
            signature: response.details.token.paymentData.signature,
            version: response.details.token.paymentData.version
           },
            geolatitude,
            geolongitude
           );

           if(this.vm.paymentSuccess)
           {
            await response.complete("success");
            debugLog("Apple Payment Processing Complete");
           }
           else
           {
            await response.complete("fail");
            debugLog("Apple Payment Processing Failed");
           }
        }
        else
        {
            debugLog("Payment Response Details Not Received From Apple Pay");            
        }
    } catch (e) {
        // Handle errors
        console.error(e);
    }
    }

    private async handleMerchantValidation(event: any)
    {
        debugLog("validating merchant session");
        console.log(event);
        await this.vm.validateAppleMerchantSession(event.validationURL);
        debugLog("validating merchant session completed");
        debugLog(this.vm.merchantSessionDetails);
        event.complete(this.vm.merchantSessionDetails?.merchantSessionResponse);
    }

    get isPaymentRequestSupported()
    {
        return this.vm.isApplePaySupported;
    }
}
