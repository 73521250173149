import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {
    VendorMerchantConfiguration, InitializeVendorUIResponse,
    AppleMerchantSessionRequest,
    ProcessApplePaymentRequest,
    ApplePayPaymentResponse,
    AppleMerchantSessionResponse
} from '@/lib/types'
import { getLogger } from '@/lib/log'

const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const debugLog = logger.debug

// eslint-disable-next-line
export namespace Request{
    export type GetTransactionIntent = {
        sourceSystem: string,
        sourceSystemIdentifier: string,
        transactionIntentId: string
    }

    export type GetPaymentOptions = {
        sourceSystem: string,
        sourceSystemIdentifier: string,
        transactionIntentId: string
    }

    export type VendorUIRequest ={
        paymentInstrumentType: string,        
        requestOrigin?: string,                
        vendor: string,
        transactionId: string,
        sourceSystem: string,
        sourceSystemIdentifier: string
    }

    export type SavePaymentRequest = {
        sourceSystem: string,
        sourceSystemIdentifier: string,        
        TransactionId: string,
        TransactionVendor: string,
        PaymentMethod: string,
        UId: string
    }
}

// eslint-disable-next-line
export namespace Response{

    export type TransactionIntentResponse = {
        transactionIntent: TransactionIntent,
        sourceSystemSupportPhoneNumber: string
    }
    export type TransactionIntent = {
        transactionVendor?: string,
        transactionFrequency: string,
        documentType: string,
        type: string,
        sourceSystem: string,
        sourceSystemIdentifier: string,
        sourceSystemProgramIdentifier: string,
        programId: string,
        transactionChannel: string,
        transactionType: string,
        recipientType?: string,
        transactionPostingType: string
        transactionAmount: TransactionAmount
        sourceSystemCorrelationId: string,
        status: TransactionStatus,
        id: string,
        transactionDescription: string
    }
    export type TransactionStatus ={
        current: Status
    }

    export type Status ={
        status: string
    }
    export type TransactionAmount ={
        currencyCode: string,
        value: number
    }
    export type PaymentOptionsResponse = {
        paymentOptions: {
            options: PaymentOption[]
        }
    }

    export type PaymentOption = {
        acceptedCardVendors: {'cardVendor': string}[];
        transactionVendor: string;
        transactionType: string;
        paymentMethod: string;
        transactionVendorMerchantConfigurations: VendorMerchantConfiguration[];
    } 

    export type PaymentDetails = {
        savePaymentResponse: SavePaymentResponse,   
        isSuccessful: boolean
    }

    export type SavePaymentResponse = {
        accountnumber: string,
        amount: string,
        bankAccountType: string,
        billingName: string,
        cardExpiry: string,
        cardNumber: string,
        cardVendor: string,
        correlationId: string,
        postalCode: string,
        status: string,
        subject: string,
        tokenId: string,
        transactionId: string,
        transactionIntentId: string,
        errorCode : string,
        errorMessage : string
    }
}

export class PaymentsApi{
    private _instance: AxiosInstance
    private _subscriptionKey: string
    private _token: string

    constructor(subscriptionKey: string, token: string, baseUri: string) {
        this._subscriptionKey = subscriptionKey
        this._token = token

        this._instance = axios.create({
            baseURL: baseUri
        })
        this._instance.interceptors.request.use(
            config => config,
            error => Promise.reject(error))
        this._instance.interceptors.response.use(
            config => config,
            error => Promise.reject(error))
    }

    public async getTransactionIntent(request: Request.GetTransactionIntent): Promise<Response.TransactionIntentResponse> {
        return await this.get<Response.TransactionIntentResponse>(
            `/transactions/intents/${request.sourceSystem}/identifier/${request.sourceSystemIdentifier}/intent/${request.transactionIntentId}`)
    }

    public async getPaymentOptions(
        request: Request.GetPaymentOptions
    ): Promise<Response.PaymentOptionsResponse> {
        return await this.get<Response.PaymentOptionsResponse>(
            `transactions/payments/${request.sourceSystem}`
            +`/identifier/${request.sourceSystemIdentifier}`
            +`/intents/${request.transactionIntentId}/options`
            )
    }

    public async fetchVendorUIDetails(
        request: Request.VendorUIRequest
    ): Promise<InitializeVendorUIResponse> {
         return await this.post<InitializeVendorUIResponse>(
            `${request.sourceSystem}`
            +`/identifier/${request.sourceSystemIdentifier}`
            +`/transaction/${request.transactionId}/initializeui`, request
            )
    }

    public async savePayment(request: Request.SavePaymentRequest): Promise<Response.PaymentDetails> 
    {
        return await this.post<Response.PaymentDetails>(`/transactions/payments/${request.sourceSystem}/identifier/${request.sourceSystemIdentifier}/transaction/${request.TransactionId}/SavePayment`,request) 
    }

    public async validateAppleMerchantSession(request: AppleMerchantSessionRequest): Promise<AppleMerchantSessionResponse> 
    {
        return await this.post<AppleMerchantSessionResponse>(`${request.sourceSystem}`
            +`/identifier/${request.sourceSystemIdentifier}`
            +`/transaction/${request.transactionId}/validateAppleMerchantSession`, request)
    }

    public async ProcessApplePayPayment(request: ProcessApplePaymentRequest): Promise<ApplePayPaymentResponse>
    {
        return await this.post<ApplePayPaymentResponse>(`transactions/payments/${request.sourceSystem}`
            +`/identifier/${request.sourceSystemIdentifier}`
            +`/transaction/${request.transactionId}/processApplePay`, request)
    }

    private async get<TResult>(
        uri: string,
        config?: AxiosRequestConfig
    ): Promise<TResult> {
        config = await this.addAuthHeader(config)
        debugLog('@@ API GET to', uri)
        try{
            const response = await this._instance.get<TResult>(uri, config)
            debugLog('@@ Success', response.data)
            return response.data as TResult
        }
        catch (err){
            debugLog('@@ Failure', err)
            throw err
        }
    }

    private async post<TResult>(
        uri: string,
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<TResult> {
        config = await this.addAuthHeader(config)
        debugLog('@@ API POST to', uri)
        try{
            const response = await this._instance.post<TResult>(uri, data, config)
            debugLog('@@ Success', response.data)
            return response.data as TResult
        }
        catch (err){
            debugLog('@@ Failure', err)
            throw err
        }
    }

    private async addAuthHeader(config?: any) {
        config = { headers: this.getAuthHeader() }
        return config
    }

    private getAuthHeader() {
        return {
            'Ocp-Apim-Subscription-key': this._subscriptionKey,
            'Authorization': `Bearer ${this._token}`
        }
    }
}


