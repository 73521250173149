export interface SessionPayload {
    sourceSystem: string
    sourceSystemIdentifier: string    
    token: string    
    transactionIntentId: string
}

export interface Payload {
    sourceSystem: string
    sourceSystemIdentifier: string
    sourceSystemProgramIdentifier: string
    profileId?: string
    requestOrigin: RequestOrigin
    sourceSystemContext: SourceSystemContext
    token: string
    preferredCultureCode: string
    transactionType: TransactionType
    amount?: number
    currencyCode: string
    sourceSystemTransactionId?: string
}

export enum RequestOrigin {
    None = 0,
    WebSelfServe = 1,
    AgentAssisted = 2,
}

export enum SourceSystemContext { 
    None = 0,
    Policy = 1,
    Claim = 2
}

export enum TransactionType {
    None = 0,
    Verify = 1,
    Authorize = 2,
    AuthorizeAndCapture = 3
}

export interface PaymentOption<TPit extends PIT = PaymentInstrumentType> {
    paymentInstrumentType: TPit,
    programId: string,
    vendor: string,
    vendorMerchantConfigs: VendorMerchantConfiguration[]
}

export enum PaymentInstrumentType {
    None = 0,
    CreditCard = 1,
    BankAccount = 2,
    EWallet = 3,
    ECheck = 4,
    BankTransfer = 5,
    ApplePay = 6
}

export type PaymentInstrumentTypeString = keyof typeof PaymentInstrumentType
type PIT = PaymentInstrumentTypeString | PaymentInstrumentType

export const pitToString = (value: PaymentInstrumentType): PaymentInstrumentTypeString => {
    return PaymentInstrumentType[value] as PaymentInstrumentTypeString
}

export const pitFromString = (value: string | PaymentInstrumentType): PaymentInstrumentType => {
    return typeof value == 'string'
        ? PaymentInstrumentType[value as PaymentInstrumentTypeString]
        : value
}

export interface PaymentInstrument<TType extends PIT = PaymentInstrumentType>
{
    paymentInstrumentType: TType,
    bankAccountDetails?: BankAccountDetails,
    cardDetails?: CardDetails,
    eCheckDetails?: ECheckDetails,
    eWalletDetails?: EWalletDetails,
    applePayDetails?: ApplePay,
}

export interface BankAccountDetails
{
    accountHolderName: string,
    accountNumber: string,
    accountType: string,
    routingNumber: string,
    consumerConsentReceived: boolean
}

export interface CardDetails{
    accountHolderName: string,
    accountNumber: string,
    brand: string,
    cvv: string,
    expiryDay?: number,
    expiryMonth: number,
    expiryYear: number,
    postalCode?: string
}

// eslint-disable-next-line
export interface ECheckDetails {
}

// eslint-disable-next-line
export interface EWalletDetails {
}

// eslint-disable-next-line
export interface ApplePay {
}

export interface VendorMerchantConfiguration {
    description: string;
    value: string;
}

export interface InitializeVendorUIRequest {
    extensions: Record<string, object>,
    paymentInstrumentType: PaymentInstrumentTypeString,
    preferredCultureCode: string,
    programIdentifier: string,
    requestOrigin: RequestOrigin,
    totalAmount: number,
    transactionType: keyof typeof TransactionType
}

export interface InitializeVendorUIResponse {
    extensions: Record<string, object>,
    orderId: string,
    uniqueIdentifier: string,
    url: string
}

export interface SaveVendorProfileRequest {
    requestOrigin: string
    sourceSystemProgramIdentifier: string;
    paymentInstrumentType: PaymentInstrumentTypeString,
    vendor: string,
    rawVendorResponse: string
}

export interface Profile {
    id: string,
    paymentInstrument: PaymentInstrument,
    sourceSystem: string,
    sourceSystemIdentifier: string,
    vendor: string,
    disabledAtUtc?: string,
    createdAtUtc?: string
}

export const currencyCodeValues = ['USD']
export const currencyDecimals: { [key in typeof currencyCodeValues[number]]: number } = {
    USD: 2
}

export type CurrencyCode = keyof typeof currencyDecimals

export interface Currency {
    currencyCode: CurrencyCode,
    value: number
}

export interface AuthorizeAndCaptureReceivableRequest {
    amount: Currency,
    sourceSystemProgramIdentifier: string,
    sourceSystemTransactionId: string
}

export interface AuthorizeAndCaptureReceivableResponse {
    amount: Currency,
    id: string,
    profileId: string,
    sourceSystem: string,
    sourceSystemIdentifier: string,
    sourceSystemTransactionId: string,
    vendor: string
}

export interface AppleMerchantSessionRequest{
    validationUrl: string,
    transactionId: string,
    sourceSystem: string,
    sourceSystemIdentifier: string,
    vendor: string
}

export interface ProcessApplePaymentRequest{
    latitude: string,
    longitude: string,
    transactionId: string,
    sourceSystem: string,
    sourceSystemIdentifier: string,
    vendor: string,
    paymentBundle: ApplePayPaymentBundle
}

export interface ApplePayPaymentBundle{
    paymentBundle: string,
    publicKey: string,
    publicKeyHash: string,
    signature: string,
    version: string
}

export interface ApplePayPaymentResponse{
    isSuccessful: boolean,
    txRefNum: boolean
}

export interface PaymentInit{
    id: string,
    total: PaymentItem
}

export interface PaymentItem{
    label: string,
    amount: PaymentCurrencyAmount
}

export interface PaymentCurrencyAmount{
    currency: string,
    value: string
}

export interface AppleMerchantSessionResponse{
    isSuccessful: boolean,
    merchantSessionResponse: JSON
}

export interface AppleMerchantValidationEvent extends Event{
    validationURL: string
}