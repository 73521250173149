
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { viewModel } from '@/lib/AppService'
import { getLogger } from '@/lib/log'
import ApplePay from './ApplePay.vue'


const logger = getLogger(process.env.VUE_APP_ENABLE_DEBUG_LOGGING)
const log = logger.write
const debugLog = logger.debug

@Component({
    components: { 
        ApplePay
    }
})

export default class DigitalWallet extends Vue {
    @Prop validationErrors: string[] = []
    vm = viewModel   
}
